@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Andada+Pro:wght@500;700&display=swap');

body {
  background: $bg-color;
}

.product-content {
  background: transparent;
}

@media (min-width: 1200px) {
  .container {
    max-width: 70%;
  }
  .product-detail .container {
    max-width: 80%;
  }
}

.w-40 {
  width: 80%;
  @include media-breakpoint-up(md) {
    width: 33.3333333333%;
  }
}

.product-content h1 {
  @include media-breakpoint-up(md) {
    //@extend .text-truncate;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
legend,
b {
  //text-transform: uppercase;
  font-weight: 900 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
legend {
  // text-transform: uppercase;
}

#root {
  padding-top: 7rem;
  @include media-breakpoint-down(sm) {
    padding-top: $calculated-navbar-height-sm;
  }
}
.card {
  border: none;
  border-radius: 0px !important;
  .bg-light {
    background-color: $gray-800 !important;
    color: $white;
  }
}
.checkout .when-valid {
  background-color: #000;
}

.navbar-light .offcanvas-collapse,
.navbar-dark .offcanvas-collapse {
  background: transparent;

  &.offcanvas-collapse-right {
    background: rgba($color: $black, $alpha: 0.9);
    h5 {
      color: white;
    }
  }

  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
  }
}

// NAV

.navbar-dark {
  background: transparent !important;
  transition: background 0.2s;
  // background-color: rgba($color: $white, $alpha: 0.9) !important;
  body[data-scrolled] & {
    background-color: $bg-color !important;
  }
}

.navbar-dark {
  .navbar-nav .nav-item .nav-link {
    //font-family: 'Visual-Regular', $font-family-sans-serif;
    //text-transform: uppercase;
    background-color: transparent;
    font-size: 1.3rem;
    color: $basetext;
  }
}

#header > .navbar {
  height: auto;
  background-color: $black;
  @include media-breakpoint-down(sm) {
    padding-top: 0.35rem;
    height: $calculated-navbar-height-sm;
  }

  .main-menu {
    flex-basis: 0 !important;
    flex-grow: 1 !important;
  }

  .navbar-brand {
    padding: 0 10px;
    @include media-breakpoint-down(sm) {
      font-size: 1.1rem;
    }
    img {
      height: 1.5rem;
      width: auto;
      max-height: 2rem;
      transition: all 0.2s ease-in-out;
    }
    @include media-breakpoint-up(md) {
      img {
        height: 4.5rem;
        width: auto;
        max-height: 4.5rem;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  .offcanvas-collapse-left {
    .navbar-nav {
      // background-color: rgba($color-dark, 0.8);
      border-radius: 0px $border-radius $border-radius 0px;

      font-family: $font-accent;

      .nav-item {
        .nav-link {
          background-color: $basetext;
          color: $bg-color;
          padding: 1.5rem;
          text-transform: uppercase;
          // font-stretch: 150%;
          letter-spacing: 3px;
        }
      }
    }
  }

  .hamburger {
    background-color: transparent !important;
  }
}

// JUMBOTRON
.header-jumbotron {
  // background-color: $dark;
  // color: $white;
  background-image: url('./assets/images/header.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transform: translateY(-10rem);
  height: 100vh;
  @include media-breakpoint-down(sm) {
    transform: translateY(-6rem);
    height: 60vh;
  }
}

// MAKI

.maki.card {
  background-color: transparent;
  color: $color-accent;

  .card-body {
    padding: $card-spacer-x;
    background: $bg-color;
    text-align: left;

    .card-title {
      span.title {
        font-weight: 900;
        // font-family: $font-accent;
        font-size: 1.2rem;
        text-transform: none;
        // max-width: 310px;
        @extend .text-truncate;
      }
      span.sub-title {
        font-size: smaller;
        margin-bottom: 5px;
      }
      span.price {
      }
    }
  }
}

.table {
  // @extend .table-dark;
}

.card,
.navbar-light .offcanvas-collapse {
  color: $black;
}

// Product Detail Page

.product-detail-right {
  display: flex;
  flex-direction: column;
  height: 100%;
  //justify-content: center;

  .product-supertitle {
    display: none;
  }
  .product-title {
    display: block;
    text-transform: uppercase;
    //margin-bottom: 1rem;
    letter-spacing: 2px;
  }
  .product-subtitle {
    font-size: $h1-font-size;
    font-weight: 900;
    font-family: $font-main !important;
    margin-bottom: 1rem;
  }
}

// Footer

.prefooter-wrapper {
  color: $black;
  background-color: $white;

  .prefooter-text {
    padding-left: 0;
    padding-right: 0;
    align-self: center;
    @include media-breakpoint-down(lg) {
      margin-bottom: 2rem;
    }
  }

  .prefooter-icon-list {
    align-items: center;
    flex-wrap: wrap;

    .row {
      justify-content: space-around;

      @include media-breakpoint-down(xs) {
        row-gap: 20px;
      }

      div {
        text-align: center;
        @include media-breakpoint-up(xxl) {
          flex: 0 0 16.6666666667% !important;
          max-width: 16.6666666667% !important;
        }
      }
    }

    img {
      max-width: 100px !important;
      max-height: 100px;
      aspect-ratio: 1;
    }
  }
}

#footer {
  color: $color-accent;
  min-height: unset;
  a {
    color: $color-accent;
  }
}

.offcanvas-collapse {
  @include media-breakpoint-up(md) {
    top: 8rem;
  }
  //box-shadow: 15px 10px 70px -35px rgba(0,0,0,0.75);
  box-shadow: none;
  background-color: transparent;
}
.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.when-valid {
  background: rgba($darkgray, 0.9);
  transition: all 0.3s;
  .modal & {
    background-color: inherit;
  }
}

.sticky-bottom.when-valid {
  &:not(.is-valid) {
    background-color: $gray-800;
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

// Loading Spinner
.loading-spinner-content {
  background: transparent !important;
}
#initial-spinner.spinner-boarder.text-dark {
  color: $color-accent !important;
}

// buttons

.btn {
  text-transform: uppercase;
}

.btn-primary {
  background-color: transparent !important;
  border-color: $color-accent;
  font-weight: 900;
  color: $color-accent;
  &:hover,
  &:active {
    background-color: $color-accent !important;
    color: $white;
  }
}

.shoppingcart-finisher,
.sticky-bottom.when-valid {
  .btn-primary {
    background-color: $black !important;
    // border-color: $white;
    font-weight: 900;
    color: $white;
    &:hover,
    &:active {
      background-color: $color-accent !important;
      color: $white;
    }
  }
  &:not(.is-valid) {
    background-color: $gray-800;
  }
}

.modal-content {
  background-color: $bg-color;
}

.list-page {
  h1 {
    font-size: 5rem;
    text-align: left;
    @include media-breakpoint-down(md) {
      font-size: 3.5rem;
    }
  }
}

#ticket-listing,
.ticket-listing {
  .ticket-header-image {
    // background-image: url('./assets/images/ticket-header-image.jpg');
    background-position: center;
    background-size: cover;
    margin: 0;
    padding: 0;
    height: 50vh;
    // height: calc(100vh - 72px);
    // @include media-breakpoint-up(md) {
    //   height: calc(100vh - 60px);
    // }
  }

  .list-page {
    padding-top: 8vh;
    // padding-left: 15vw;
    // padding-right: 15vw;
    // @include media-breakpoint-down(md) {
    //   padding-left: 8vw;
    //   padding-right: 8vw;
    // }
  }

  .nigiri.card {
    background-color: transparent;
    border-bottom: 1px solid $black;
    border-radius: none !important;

    //border-bottom: 1px solid rgba($white, 0.25);
    .nigiri-content {
      padding: 1.25rem;
      margin-top: 0.2rem;
      color: $black;
      @include media-breakpoint-up(md) {
        align-items: center;
      }
      @include media-breakpoint-down(md) {
        display: table;
      }
    }
    .nigiri-date {
      font-size: $h2-font-size;
      font-weight: 700;
      color: $primary;
      align-items: center !important;
      @include media-breakpoint-down(md) {
        font-size: $h5-font-size;
      }
    }
    .nigiri-body {
      font-size: $h2-font-size;
      border-left: 0;
      text-transform: uppercase;
      font-weight: bold;
      padding-left: 1rem;
      @include media-breakpoint-down(md) {
        padding-left: 0;
        line-height: 2rem;
      }
    }
    .badge {
      z-index: 2;
      font-size: 0.8em;
    }
    .title {
      font-size: $font-size-base * 1.2;
      margin-bottom: 0;
      color: $primary;
      font-family: $font-family-sans-serif !important;
    }
    .subtitle {
      font-size: $font-size-sm;
      line-height: 0.5rem;
      margin-bottom: 0;
      color: $primary;
      font-weight: normal;
      @include media-breakpoint-down(md) {
        line-height: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
    .location {
      font-family: $font-family-sans-serif !important;
      font-weight: 600 !important;
      font-size: $h2-font-size !important;
      color: $primary;
      @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
      }
    }
    .nigiri-cta > .btn {
      font-size: 1.2rem;
      background-color: $primary;
      border-color: $primary;
      border-radius: 2.5rem;
      box-shadow: none;
      letter-spacing: normal;
      font-weight: 900;
    }
    // &:hover {
    //   background-color: $primary;
    //   // color: $bg-color !important;
    //   transition: ease-in-out 0.1s;
    // }
  }
}

.ticket-detail-page {
  .blurry-background-image {
    //height: 50vh;
    background-color: transparent;
  }

  .blurry-background-image:before {
    display: none;
  }

  // .blurry-background-image:before {
  //   background-image: none;
  // }
  .carousel-inner {
    //display: none !important;
  }

  .article-title {
    padding-left: 0;
    color: $primary;
    padding-right: 0;
    background: transparent !important;
    border: 0;
    //line-height: 1.4;

    .super-title {
      font-size: 0.7rem;
      opacity: 1;
    }
    .sub-title {
      font-weight: 500;
      margin-top: 0.5rem;
    }
  }

  .product-info-bar {
    background-color: transparent;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    //border-bottom: 0.1rem solid rgba($basetext, 0.08);
    box-shadow: none;
    i.far {
      color: $secondary !important;
    }
    .navbar-text {
      text-align: left;
      margin-left: 0;
      display: block;
    }
  }

  .detail-page {
    padding-top: 0;
  }

  .variant-listing {
    .visible,
    .invisible {
      display: none !important;
    }
  }
}
